.App {
  text-align: center;
  height: 100%;
}

.screen {
  z-index: -1;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-color: #bab8a3;
}

#compBorder {
  z-index: 0;
  position: absolute;
  top: -25%;
  left: -26.5%;
  height: 160%;
  width: 145%;
}

.compBordersticker {
  height: 100%;
  width: 100%;
}

#screenIsOn {
  z-index: 1;
  display: none;
  position: absolute;
  background-color: black;
  height: 76%;
  width: 75%;
  top: 11%;
  left: 13%;
  animation: screen-turn-on 3s linear forwards;
}

@keyframes screen-turn-on {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: 0;
  }
}

#screenIsOff {
  z-index: 1;
  display: block;
  position: absolute;
  background-color: black;
  height: 76%;
  width: 75%;
  top: 11%;
  left: 13%;
}

#screenIsOffAnimation {
  z-index: 0;
  display: block;
  position: absolute;
  background-color: black;
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  animation: screen-turn-off .15s ease 0s 1, screen-turn-off2 .35s ease .15s 1;
}

@keyframes screen-turn-off {
	0% {
    background-color: white;
		height: 100%;
    width: 100%;
	}
	100% {
    background-color: white;
    top: 50%;
    height: 1%;
    width: 100%;
	}
}

@keyframes screen-turn-off2 {
	0% {
    background-color: white;
    top: 50%;
		height: 1%;
    width: 100%;
	}
	100% {
    background-color: white;
    top: 50%;
    left: 50%;
    height: 1%;
    width: 1%;
	}
}

#innerBorder {
  z-index: 0;
  position: absolute;
  background-color: #008282;
  height: 76%;
  width: 75%;
  top: 11%;
  left: 13%;
}

#taskbar {
  position: absolute;
  background: #BFC5C5;
  height: 7%;
  width: 100%;
  left: 0%;
  bottom: 0%;
}

.taskbarsticker {
  height: 100%;
  width: 100%;
}

#timeSticker {
  position: absolute;
  height: 100%;
  width: 11%;
  right: 0%;
}

.timeSticker {
  height: 100%;
  width: 100%;
}

#startButton {
  z-index: 0;
  position: absolute;
  border: outset;
  text-align: center;
  font-size: .9vw;
  height: 80%;
  width: 6%;
  cursor: pointer;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

#searchBar {
  z-index: 0;
  position: absolute;
  background: white;
  border: inset;
  height: 50%;
  width: 15%;
  left: 7%;
  top: 4px;
}

#flicker {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  background: rgba(18, 16, 16, 0.2);
  opacity: 0;
  z-index: 1000;
  pointer-events: none;
  animation: flicker .12s infinite;
}

@keyframes flicker {
  0% {
    opacity: 0.552;
  }
  5% {
    opacity: 0.48287;
  }
  10% {
    opacity: 0.59134;
  }
  15.0% {
    opacity: 0.79543;
  }
  20% {
    opacity: 0.75134;
  }
  25% {
    opacity: 0.1956;
  }
  30.0% {
    opacity: 0.90687;
  }
  35% {
    opacity: 0.122;
  }
  40% {
    opacity: 0.62254;
  }
  45% {
    opacity: 0.56977;
  }
  50% {
    opacity: 0.9925;
  }
  55.0% {
    opacity: 0.55487;
  }
  60.0% {
    opacity: 0.16607;
  }
  65% {
    opacity: 0.12353;
  }
  70% {
    opacity: 0.2214;
  }
  75% {
    opacity: 0.67908;
  }
  80% {
    opacity: 0.97163;
  }
  85.0% {
    opacity: 0.1275;
  }
  90% {
    opacity: 0.37186;
  }
  95% {
    opacity: 0.24475;
  }
  100% {
    opacity: 0.37221;
  }
}

#cmdIconOnClick {
  position: absolute;
  bottom: 27%;
  left: 6%;
  height: 6%;
  width: 5%;
  cursor: pointer;
}

#cmdIcon {
  z-index: -1;
  position: absolute;
  bottom: 27%;
  left: 6%;
  background: black;
  color: white;
  font-size: 10px;
  text-align: left;
  height: 6%;
  width: 5%;
  border: solid;
  border-color: white;
  border-width: 1px;
  border-top-width: 3px;
}

#cmdIconText {
  z-index: -1;
  bottom: 20%;
  left: 5.9%;
  position: absolute;
  color: white;
  font-size: .9vw;
  text-shadow: 2px 2px #000000;
}

#recycleBin {
  z-index: -1;
  position: fixed;
  top: 18%;
  left: 15%;
  height: 7%;
  width: 10%;
}

.recycleBinsticker {
  height: 100%;
  width: 100%;
}

#recycleBinText {
  z-index: -1;
  top: 25%;
  left: 17.5%;
  position: fixed;
  color: white;
  font-size: .9vw;
  text-shadow: 2px 2px #000000;
}

#myComp {
  z-index: -1;
  position: fixed;
  top: 30%;
  left: 15.5%;
  height: 10%;
  width: 8%;
}

.myCompsticker {
  height: 100%;
  width: 100%;
}

#myCompText {
  z-index: -1;
  top: 38.5%;
  left: 16.7%;
  position: fixed;
  color: white;
  font-size: .9vw;
  text-shadow: 2px 2px #000000;
}

#lol {
  z-index: -1;
  position: fixed;
  top: 46%;
  left: 18%;
  height: 6%;
  width: 3%;
}

.lolsticker {
  height: 100%;
  width: 100%;
}

#loltext {
  z-index: -1;
  top: 52%;
  left: 17.5%;
  position: fixed;
  color: white;
  font-size: .9vw;
  text-shadow: 2px 2px #000000;
}

#matrixRain {
  z-index: -1;
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
}

#cmdApp {
  display: block;
  z-index: 0;
  position: absolute;
  background: black;
  height: 60%;
  width: 45%;
  left: 2%;
  top: 3%;
  border-style: outset;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

#cmd-program {
  position: absolute;
  color: white;
  font-family: Consolas;
  font-size: .9vw;
  text-align: left;
  top: 10%;
  left: 1%;
}

#cmd-program-line-tag {
  position: absolute;
  color: white;
  font-family: Consolas;
  font-size: .9vw;
  top: 0%;
  left: 0;
}

#cursor {
  z-index: 1;
  position: absolute;
  color: white;
  top: 0%;
  left: 57%;
  font-size: .8vw;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#cmdtitlearea {
  position: absolute;
  background: white;
  height: 25px;
  width: 100%;
  left: 0;
  top: 0;
}

#cmd-img {
  position: absolute;
  height: 100%;
  width: 7%;
  top: 0%;
  left: 0%;
}

#cmdApp-img {
  height: 100%;
  width: 100%;
  padding: 0%;
}
  
#cmd-title {
  position: absolute;
  top: 15%;
  left: 6%;
  font-size: .9vw;	
}

.closeButton {
  position: absolute;
  text-align: center;
  font-size: large;
  background-color: red;
  height: 96%;
  width: 7%;
  right: 0%;
  cursor: pointer;
}

#menu {
  position: absolute;
  outline: none;
  top: 25%;
  left: 1.5%;
  height: 50%;
  width: 96%;
}

#menu1 {
  position: absolute;
  width: 50%;
  left: 33%;
  top: 0%;
  color: white;
  font-family: Consolas;
  font-size: .9vw;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  cursor: pointer;
}

#menu2 {
  position: absolute;
  width: 50%;
  left: 33%;
  top: 11%;
  color: white;
  font-family: Consolas;
  font-size: .9vw;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  cursor: pointer;
}

#menu3 {
  position: absolute;
  width: 50%;
  left: 33%;
  top: 22%;
  color: white;
  font-family: Consolas;
  font-size: .9vw;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  cursor: pointer;
}

#menu4 {
  position: absolute;
  width: 50%;
  left: 33%;
  top: 33%;
  color: white;
  font-family: Consolas;
  font-size: .9vw;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  cursor: pointer;
}

.sticker1 {
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
} 

#mfknSticker1 {
  position: relative;
  transform: rotate(-45deg);
  top: 2%;
  left: 0%;
  height: 11%;
  width: 7%;
}

#mfknSticker2 {
  position: relative;
  transform: rotate(45deg);
  top: -10%;
  right: -95%;
  height: 11%;
  width: 7%;
}

#mfknSticker3 {
  position: relative;
  transform: rotate(60deg);
  bottom: -50%;
  left: -3%;
  height: 17%;
  width: 12%;
}

.dbzsticker {
  height: 100%;
  width: 100%;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

#dbzsticker {
  position: fixed;
  top: 92%;
  left: 84.5%;
  height: 10%;
  width: 10%;
}

.walltitansticker {
  height: 100%;
  width: 100%;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

#walltitansticker {
  position: fixed;
  top: 25%;
  left: 88%;
  height: 17%;
  width: 6%;
  transform: scaleX(-1);
}

.madara {
  height: 100%;
  width: 100%;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

#madara {
  position: fixed;
  top: 30%;
  left: -1%;
  height: 27%;
  width: 10%;
  transform: scaleX(-1);
}

.kaneki {
  height: 100%;
  width: 100%;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

#kaneki {
  position: fixed;
  transform: rotate(15deg);
  top: 60%;
  right: -1%;
  height: 18%;
  width: 10%;
}

.smiley {
  height: 100%;
  width: 100%;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

#smiley {
  position: fixed;
  transform: rotate(15deg);
  top: 90%;
  left: 10%;
  height: 15%;
  width: 8%;
  overflow: hidden;
}

.postit {
  height: 100%;
  width: 100%;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

#postit {
  z-index: 4;
  position: fixed;
  top: 0%;
  left: 1%;
  height: 30%;
  width: 17%;
  overflow: hidden;
}

#postit2 {
  z-index: 4;
  position: fixed;
  top: 91%;
  right: 15%;
  height: 30%;
  width: 17%;
  overflow: hidden;
}

.powerImage {
  position: absolute;
  transform: rotate(180deg);
  opacity: .2;
  height: 50%;
  width: 50%;
  bottom: 25%;
  left: 26%;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

#powerbutton {
  position: fixed;
  left: 88%;
  top: 94%;
  height: 6%;
  width: 3%;
  background-color: #bab8a3;
  border-radius: 50%;
  border-style: inset;
  transform: rotate(180deg);
  cursor: pointer
}

#powerbuttonled {
  position: fixed;
  left: 92%;
  top: 96%;
  height: 1.5%;
  width: .75%;
  background-color: red;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 2.5px red;
}

#aboutPage-title {
  position: absolute;
  top: 15%;
  left: 1%;
  font-size: .9vw;	
}

#aboutPageApp {
  z-index: 1;
  display: none;
  position: absolute;
  background: black;
  height: 60%;
  width: 37%;
  right: 3%;
  bottom: 8%;
  border-style: outset;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

#aboutText {
  display: block;
  position: absolute;
  color: #cf102b;
  text-align: center;
  font-size: .8vw;
  top: 5%;
  left: 7.5%;
}

#aboutText3 {
  display: none;
  position: absolute;
  color: #cf102b;
  text-align: center;
  font-size: .4vw;
  top: 14%;
  left: 25%;
  animation: glitch-animation-2 .12s 100;
}

@keyframes glitch-animation-2 {
  0% {
    color: rgba(207, 16, 43, 0.5);
  }
  5% {
    color: rgba(207, 16, 43, 0.4);
  }
  10% {
    color: rgba(207, 16, 43, 0.5);
  }
  15.0% {
    color: rgba(207, 16, 43, 0.7);
  }
  20% {
    color: rgba(207, 16, 43, 0.7);
  }
  25% {
    color: rgba(207, 16, 43, 0.1);
  }
  30.0% {
    color: rgba(207, 16, 43, 0.9);
  }
  35% {
    color: rgba(207, 16, 43, 0.1);
  }
  40% {
    color: rgba(207, 16, 43, 0.6);
  }
  45% {
    color: rgba(207, 16, 43, 0.5);
  }
  50% {
    color: rgba(207, 16, 43, 0.9);
  }
  55.0% {
    color: rgba(207, 16, 43, 0.5);
  }
  60.0% {
    color: rgba(207, 16, 43, 0.1);
  }
  65% {
    color: rgba(207, 16, 43, 0.1);
  }
  70% {
    color: rgba(207, 16, 43, 0.2);
  }
  75% {
    color: rgba(207, 16, 43, 0.6);
  }
  80% {
    color: rgba(207, 16, 43, 0.9);
  }
  85.0% {
    color: rgba(207, 16, 43, 0.1);
  }
  90% {
    color: rgba(207, 16, 43, 0.3);
  }
  95% {
    color: rgba(207, 16, 43, 0.2);
  }
  100% {
    color: rgba(207, 16, 43, 0.3);
  }
}

#aboutText2 {
  display: none;
  position: absolute;
  color: rgba(207, 16, 43, 1);
  text-align: center;
  font-size: .7vw;
  top: 40%;
  left: 10%;
  animation: glitch-animation .12s 100;
}

@keyframes glitch-animation {
  0% {
    color: rgba(207, 16, 43, 0.5);
  }
  5% {
    color: rgba(207, 16, 43, 0.4);
  }
  10% {
    color: rgba(207, 16, 43, 0.5);
  }
  15.0% {
    color: rgba(207, 16, 43, 0.7);
  }
  20% {
    color: rgba(207, 16, 43, 0.7);
  }
  25% {
    color: rgba(207, 16, 43, 0.1);
  }
  30.0% {
    color: rgba(207, 16, 43, 0.9);
  }
  35% {
    color: rgba(207, 16, 43, 0.1);
  }
  40% {
    color: rgba(207, 16, 43, 0.6);
  }
  45% {
    color: rgba(207, 16, 43, 0.5);
  }
  50% {
    color: rgba(207, 16, 43, 0.9);
  }
  55.0% {
    color: rgba(207, 16, 43, 0.5);
  }
  60.0% {
    color: rgba(207, 16, 43, 0.1);
  }
  65% {
    color: rgba(207, 16, 43, 0.1);
  }
  70% {
    color: rgba(207, 16, 43, 0.2);
  }
  75% {
    color: rgba(207, 16, 43, 0.6);
  }
  80% {
    color: rgba(207, 16, 43, 0.9);
  }
  85.0% {
    color: rgba(207, 16, 43, 0.1);
  }
  90% {
    color: rgba(207, 16, 43, 0.3);
  }
  95% {
    color: rgba(207, 16, 43, 0.2);
  }
  100% {
    color: rgba(207, 16, 43, 0.3);
  }
}

#storePage-title {
  position: absolute;
  top: 15%;
  left: 1%;
  font-size: .9vw;	
}

#storePageApp {
  opacity: 0;
  z-index: -1;
  display: none;
  position: absolute;
  background: black;
  height: 90%;
  width: 80%;
  left: 10%;
  top: 2%;
  border-style: outset;
  overflow: hidden;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

#storePageBody {
  position: absolute;
  top: 12%;
  height: 90%;
  width: 100%;
  overflow: auto;
}

#storePageBody2 {
  z-index: 2;
  position: absolute;
  top: 12%;
  height: 90%;
  width: 100%;
  overflow: auto;
  color: #cf102b;
}

#collection-component-1675208705560 {
  position: absolute;
  top: 5%;
  left: 0%;
}

#streamPageApp {
  z-index: 2;
  display: block;
  position: absolute;
  background: black;
  height: 70%;
  width: 50%;
  right: 1%;
  top: 1%;
  border-style: outset;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

#streamPageApp-title {
  position: absolute;
  left: 7%;
}

#streamPageApp-img-div {
  position: absolute;
  height: 90%;
  width: 7%;
  left: 0%;
}

#streamPageApp-img {
  height: 100%;
  width: 100%;
}

#contactPage-title {
  position: absolute;
  top: 15%;
  left: 1%;
  font-size: .9vw;	
}

#contactPageApp {
  z-index: 1;
  display: none;
  position: absolute;
  background: black;
  height: 45%;
  width: 30%;
  left: 5%;
  top: 45%;
  border-style: outset;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  overflow: hidden;
}

#contactForm {
  position: absolute;
  top: 12%;
  left: 1%;
  height: 90%;
  width: 99%;
}

#fullname {
  position: absolute;
  height: 10%;
  width: 90%;
  left: 2%;
  top: 1%;
}

#email {
  position: absolute;
  height: 10%;
  width: 90%;
  left: 2%;
  top: 16%;
}

#message {
  position: absolute;
  height: 50%;
  width: 90.5%;
  left: 2%;
  top: 31%;
  overflow: hidden;
  resize: none;
}

#submit {
  position: absolute;
  height: 8%;
  left: 2%;
  top: 87%;
}

#thankYouDiv {
  display: none;
  z-index: 0;
  position: absolute;
  background: black;
  height: 40%;
  width: 45%;
  left: 20%;
  top: 15%;
  border-style: outset;
}

#thankYouDivTitle {
  position: absolute;
  background: white;
  height: 20%;
  width: 100%;
  left: 0;
  top: 0;
}

#thankYouMessage {
  position: absolute;
  top: 25%;
  left: 5%;
  color: #cf102b;
  font-size: small;
}

#twitch-embed {
  background-color: #0072e0;
  height: 100%;
  width: 100%;
}

#streamTitleArea {
  position: absolute;
  background: white;
  height: 7%;
  width: 100%;
  left: 0;
  top: 0;
}

#linkBlocker {
  display: none;
  z-index: 1;
  position: absolute;
  background: #000000;
  height: 20%;
  width: 100%;
  bottom: 0%;
}

#discordicondiv {
  position: absolute;
  bottom: 6%;
  right: 15%;
  height: 12%;
  width: 9%;
}

#discordicon {
  height: 100%;
  width: 100%;
}

#instagramicondiv {
  position: absolute;
  bottom: 6%;
  left: 15%;
  height: 12%;
  width: 9%;
}

#instagramicon {
  height: 100%;
  width: 100%;
}

#tiktokicondiv {
  position: absolute;
  bottom: 6%;
  left: 45%;
  height: 12%;
  width: 9%;
}

#tiktokicon {
  height: 100%;
  width: 100%;
}










